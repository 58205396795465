<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" lg="6" xl="6" class="mx-auto my-8">
        <v-card elevation="2">
          <v-card-text>
            <v-card-title
              class="text-center"
              style="justify-content: center;"
            >Maak veilig een kopie van uw ID.</v-card-title>
            <v-divider></v-divider>
            <v-form class="mt-4" ref="form" @submit.prevent="submitForm" lazy-validation>
              <image-canvas
                class="image-canvas-container"
                v-if="imageDataUrl"
                :imageDataUrl="imageDataUrl"
                @imageUpdated="onImageUpdated"
              ></image-canvas>

              <v-file-input
                :rules="[requiredObject('Afbeelding')]"
                label="Afbeelding"
                @change="uploadFile"
                v-model="uploadedFile"
                show-size
                accept="image/png, image/jpeg"
                prepend-icon
                filled
                hint="Mag niet groter zijn dan 25MB."
                persistent-hint
                prepend-inner-icon="mdi-camera"
                @click:clear="resetImage"
              ></v-file-input>
              <v-text-field
                label="Voornaam"
                v-model="firstname"
                filled
                :rules="[required('Voornaam'),maxLength('Voornaam',150)]"
                required
              ></v-text-field>
              <v-text-field
                label="Achternaam"
                v-model="lastname"
                filled
                :rules="[required('Achternaam'),maxLength('Achternaam',150)]"
                required
              ></v-text-field>
              <v-text-field
                label="Email"
                v-model="email"
                filled
                prepend-inner-icon="mdi-email"
                :rules="[required('Email'), emailFormat(),maxLength('Email',150)]"
                required
              ></v-text-field>
              <v-text-field
                label="Telefoonnummer"
                v-model="phone"
                filled
                prepend-inner-icon="mdi-phone"
                hint="06 12345678 of +31612345678"
                :rules="[required('Telefoonnummer'), phoneFormat(), maxLength('Telefoonnummer',150)]"
                required
              ></v-text-field>

              <v-autocomplete
                v-model="adress"
                :items="locations"
                :loading="searchingLocation"
                :search-input.sync="adressSearchTerm"
                no-filter
                item-text="place_name_nl"
                item-value="id"
                label="Adres"
                prepend-inner-icon="mdi-map-marker"
                hide-no-data
                required
                filled
                clearable
                return-object
                hint="Bijvoorbeeld: Hoek 54 Bergeijk"
              ></v-autocomplete>

              <v-checkbox
                :rules="[v => !!v || 'Je moet akkoord gaan met onze privacy voorwaarden!']"
                v-model="agreedWithTermsAndConditions"
                label="Ik ga akkoord met de privacy voorwaarden."
              >
                <template v-slot:label>
                  <div @click.stop>
                    Ik ga akkoord met de
                    <a
                      href="/privacy_statement.pdf"
                      target="_blank"
                      @click.stop="terms = true"
                    >privacy voorwaarden.</a>
                  </div>
                </template>
              </v-checkbox>
              <v-btn type="submit" block color="primary" class="mt-4" :loading="sending">Versturen</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Validation from "../utils/validation";
import axios from "axios";
import ImageCanvas from "./ImageCanvas.vue";

export default {
  name: "MainForm",
  components: {
    ImageCanvas,
  },
  methods: {
    ...Validation,

    submitForm() {
      if (this.$refs.form.validate()) {
        this.sending = true;
        if (this.adress === "undefined") {
          this.adress = null;
        }

        var regex = /(?:\.([^.]+))?$/;
        var data = {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          adress: this.adress.place_name_nl,
          file: dataURLtoFile(
            this.imageDataUrl,
            `KopieId ${this.firstname} ${this.lastname}.${
              regex.exec(this.uploadedFile.name)[1]
            }`
          ),
          imageDataUrl: null,
        };

        const formData = new FormData();
        Object.keys(data).forEach((key) => formData.append(key, data[key]));

        axios({
          method: "post",
          url: "https://jansenverhuurapi.nl/api/id",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(() => {
            this.$router.push("/success");
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },
    uploadFile() {
      this.imageDataUrl = null;
      if (this.uploadedFile) {
        var reader = new FileReader();

        reader.addEventListener("load", () => {
          this.imageDataUrl = reader.result;
        });

        reader.readAsDataURL(this.uploadedFile);
      }
    },
    resetImage() {
      this.imageUrl = "";
    },
    searchLocation() {
      this.searchingLocation = true;
      this.locations = [];
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(
            this.adressSearchTerm
          )}.json?language=nl&access_token=${this.accessToken}`
        )
        .then((result) => {
          result.data.features.forEach((element) => {
            this.locations.push(element);
          });
        })
        .finally(() => {
          this.searchingLocation = false;
        });
    },
    onImageUpdated(updatedImageDateUrl) {
      this.imageDataUrl = updatedImageDateUrl;
    },
  },

  data: () => ({
    uploadedFile: null,
    imageDataUrl: null,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    agreedWithTermsAndConditions: false,
    adress: "",
    adressSearchTerm: "",
    sending: false,
    searchingLocation: false,
    locations: [],
    accessToken:
      "pk.eyJ1Ijoiam9lcHZlcmhvZXZlbiIsImEiOiJja2UxaGpxeDg0NHV0MndtcTRjcDhqcjZiIn0.9mnih2kMWGcp_WhtJa4avw",
  }),

  watch: {
    adressSearchTerm(value) {
      if (!value) {
        return;
      }

      this.searchLocation();
    },
  },
};

function dataURLtoFile(dataurl, fileName) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}
</script>

<style scoped>
.image-canvas-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  border: 2px solid;

  margin-bottom: 10px;
  background-color: #ffffff;
  border-width: thin;
}
</style>
