<template>
  <div>
    <canvas
      id="canvas"
      ref="canvas"
      :width="width"
      :height="height"
      style="touch-action: none;"
    >Your browser does not support the canvas element.</canvas>
    <v-btn v-if="cStep > 0" block color="primary" @click="cUndo">Ongedaan maken</v-btn>
  </div>
</template>

<script>
export default {
  name: "ImageCanvas",
  components: {},
  props: {
    imageDataUrl: String,
  },
  computed: {},
  data() {
    return {
      width: 500,
      height: 300,
      image: null,
      canvas: undefined,
      ctx: undefined,
      mousePressed: false,
      lastY: undefined,
      lastX: undefined,
      originalState: undefined,
      cPushArray: [],
      cStep: -1,
    };
  },
  mounted() {
    // Get canvas and context.
    this.canvas = this.$refs.canvas;
    this.ctx = this.canvas.getContext("2d");
    this.ctx.strokeStyle = "#222222";
    this.ctx.lineWidth = 10;
    // Load the given image into the canvas.
    this.loadImageInCavas(this.imageDataUrl);
  },
  methods: {
    /**
     * Loads the initial image from the given data.
     */
    loadImageInCavas(dataUrl) {
      var image = new Image();
      image.onload = () => {
        this.scaleToFit(image);
      };
      image.src = dataUrl;
    },
    /**
     * Scales the image to fit in the canvas.
     */
    scaleToFit(img) {
      // get the scale
      var scale = Math.min(this.width / img.width, this.height / img.height);
      // get the top left position of the image
      var x = this.width / 2 - (img.width / 2) * scale;
      var y = this.height / 2 - (img.height / 2) * scale;
      this.ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      this.addWatermarkToCanvas();
    },
    /**
     * Adds a watermark on the image in the canvas.
     */
    addWatermarkToCanvas() {
      const now = new Date();
      const watermarkText = `Jansen Verhuur Bergeijk ${now.toLocaleDateString()}`;
      this.ctx.globalCompositeOperation = "source-atop";
      this.ctx.font = "16px Arial";
      this.ctx.fillText(
        watermarkText,
        this.width / 2 - this.ctx.measureText(watermarkText).width / 2,
        this.height / 2
      );
      this.onImageUpdated();
      this.ctx.globalCompositeOperation = "source-over";
      this.originalState = this.canvas.toDataURL();
      this.addCanvasEventListeners();
    },
    /**
     * Resets the canvas to the original state keeping the image and watermark intact while removing the user drawn elements
     */
    undo() {
      if (this.cStep > 0) {
        this.cStep--;
        var canvasPic = new Image();
        canvasPic.src = this.cPushArray[this.cStep];
        canvasPic.onload = function () {
          var canvas = document.getElementById("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(canvasPic, 0, 0);
        };
      }
    },
    addCanvasEventListeners() {
      this.canvas.addEventListener(
        "mousedown",
        (e) => {
          var bounds = this.canvas.getBoundingClientRect();
          this.lastX = e.clientX - bounds.left;
          this.lastY = e.clientY - bounds.top;
          this.mousePressed = true;
        },
        false
      );
      this.canvas.addEventListener(
        "mousemove",
        (e) => {
          if (this.mousePressed) {
            var bounds = this.canvas.getBoundingClientRect();
            console.log("drawing");
            this.draw(e.clientX - bounds.left, e.clientY - bounds.top, false);
          }
        },
        false
      );
      this.canvas.addEventListener(
        "mouseup",
        () => {
          this.mousePressed = false;
          this.cPush();
          this.onImageUpdated();
        },
        false
      );
      this.canvas.addEventListener(
        "mouseleave",
        () => {
          if (this.mousePressed) {
            this.mousePressed = false;
            this.cPush();
            this.onImageUpdated();
          }
        },
        false
      );
      this.canvas.addEventListener(
        "touchstart",
        (e) => {
          var touch = e.touches[0];
          var mouseEvent = new MouseEvent("mousedown", {
            clientX: touch.clientX,
            clientY: touch.clientY,
          });
          this.canvas.dispatchEvent(mouseEvent);
        },
        false
      );
      this.canvas.addEventListener(
        "touchend",
        () => {
          var mouseEvent = new MouseEvent("mouseup", {});
          this.canvas.dispatchEvent(mouseEvent);
        },
        false
      );
      this.canvas.addEventListener(
        "touchmove",
        (e) => {
          var touch = e.touches[0];
          var mouseEvent = new MouseEvent("mousemove", {
            clientX: touch.clientX,
            clientY: touch.clientY,
          });
          this.canvas.dispatchEvent(mouseEvent);
        },
        false
      );
      this.cPush();
    },
    draw(x, y) {
      if (this.mousePressed) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = "#222222";
        this.ctx.lineWidth = 10;
        this.ctx.lineJoin = "round";
        this.ctx.moveTo(this.lastX, this.lastY);
        this.ctx.lineTo(x, y);
        this.ctx.closePath();
        this.ctx.stroke();
      }
      this.lastX = x;
      this.lastY = y;
    },
    cPush() {
      this.cStep++;
      if (this.cStep < this.cPushArray.length) {
        this.cPushArray.length = this.cStep;
      }
      this.cPushArray.push(document.getElementById("canvas").toDataURL());
    },
    cUndo() {
      if (this.cStep > 0) {
        this.cStep--;
        var canvasPic = new Image();
        canvasPic.src = this.cPushArray[this.cStep];

        canvasPic.onload = function () {
          var canvas = document.getElementById("canvas");
          var ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(canvasPic, 0, 0);
        };
      }
    },
    onImageUpdated() {
      this.$emit("imageUpdated", this.$refs.canvas.toDataURL());
    },
  },
  watch: {
    imageDataUrl: (oldValue, newValue) => {
      this.loadImageInCavas(newValue);
    },
  },
};
</script>

<style>
</style>