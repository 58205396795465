<template>
    <MainForm />
</template>

<script>
// @ is an alias to /src
import MainForm from '@/components/MainForm.vue'

export default {
  name: 'Home',
  components: {
    MainForm
  }
}
</script>
